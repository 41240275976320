// for naming colors use: http://chir.ag/projects/name-that-color/ cause your "celestito" may not be my "celestito"
// https://www.rgbtohex.net/hextorgb/
/* RGB */
$violet: rgb(115, 98, 251);//#7362FB;
$green: rgb(38, 228, 204);//#26E4CC;
$white: rgb(255, 255, 255);
$blue: rgb(43, 40, 150); //#2b289f
$light-gray: rgb(210, 212, 215);
$light-gray-alter: rgb(232, 232, 232); //#e8e8e8
$super-light-gray: rgb(180, 181, 181); //FAFBFB
$cotton-candy: rgb(255, 64, 129); //ff4081
$mine-shaft: rgb(37, 37, 37); //#252525;
$hover-table: $green; //#4a928a
$main-color: $violet;
$contrast-color: $green ;
$hover-button-color: $green;
$enabled: rgb(22, 161, 65);
$disabled: rgb(207, 21, 21);
$deleted: rgb(108, 108, 108);
$stripe: rgb(238, 234, 244); //#EEEAF4
$error-color: #f3705a;
$success-color: $green;
$regular-color: rgba(159, 204, 223, 1);
$light-bg: #EEEAF4; //#f7f7f7
$dark-bg: rgb(66,66,66); //#424242


$primary-primary-color: #1D1482;
$primary-secondary-color: #C3BEF7;
