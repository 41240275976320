@use '@angular/material' as mat;
@include mat.core();
// @import '../../../subapps/mmk/shared/styles/theme/components-themes';

// Define a dark theme
$dark-theme: mat.define-dark-theme((
 color: (
   primary: mat.define-palette(mat.$teal-palette),
   accent: mat.define-palette(mat.$indigo-palette),
 ),
  // Only include `typography` and `density` in the default dark theme.
  typography: mat.define-typography-config(),
  density: 0,
));

// Define a light theme
$light-theme: mat.define-light-theme((
 color: (
   primary: mat.define-palette(mat.$teal-palette),
   accent: mat.define-palette(mat.$indigo-palette),
 ),
));

// Apply the light theme by default
@include mat.all-component-themes($light-theme);



// Apply the darj theme only when the user prefers light themes.
// @media (prefers-color-scheme: dark) {
//  // Use the `-color` mixins to only apply color styles without reapplying the same
//  // typography and density styles.
//  @include mat.core-color($dark-theme);
//  @include mat.button-color($dark-theme);
// }
//
// $my-primary: mat.define-palette(mat.$indigo-palette, 500);
// $my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
//
// // The "warn" palette is optional and defaults to red if not specified.
// $my-warn: mat.define-palette(mat.$red-palette);
//
// $my-theme: mat.define-light-theme((
//  color: (
//    primary: $my-primary,
//    accent: $my-accent,
//    warn: $my-warn,
//  ),
//  typography: mat.define-typography-config(),
//  density: 0,
// ));
// // @include angular-material-theme($my-custom-theme);
// // @include mmk-component-themes($my-custom-theme);
// @include mat.core-theme($my-theme);
// // Our dark theme
.dark-theme {
  @include mat.all-component-colors($dark-theme);
  --mat-dialog-container-max-width: 95vw;
}
