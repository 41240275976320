// @import '@angular/material/prebuilt-themes/purple-green.css';
@import 'variables';
@import 'theme';
@import 'breakpoints';
@import 'palette';
@import 'typography';
@import 'buttons';

html,
body {
  height: 100%;
}

ul {
  padding: 0;
  margin: 0;
}

button {
  -webkit-appearance: none;
  appearance: none;
}

svg {
  width: 100%;
}

//custom props
body {
  font-size: 16px; //base
  font-family: $font-family;
  // background-color: rgba(0,0,0,.9);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
// ::-webkit-scrollbar-track {
//   background: #f1f1f1;
// }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// .mat-mdc-header-row {
//   background-color: $blue;
// }
//
// .dark-theme .mat-mdc-header-row {
//   background-color: $blue;
// }


// overrides globales
.dark-theme .app-sidenav-container .app-sidenav-container__powered__logo {
  color: $primary-secondary-color;
}

.mat-mdc-slide-toggle .mdc-form-field {
  color: $blue;
}


.example-month-picker .mat-calendar-header .mat-calendar-controls {
  display: none;
}

.mat-mdc-option {
  border-bottom: 1px solid lightgrey;
}

.cdk-global-overlay-wrapper {
  justify-content: center !important;
}