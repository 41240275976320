@import './variables.scss';
button {
  background-color: transparent;
  border: 1px solid transparent;
  padding: .5rem 1rem;
  border-radius: 4px;
  transition: all .3s ease-in-out;
  font-family: $font-family;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1;
}

button:not(:disabled) {
  cursor: pointer;
}


.primary-btn {
  background-color: $blue;
  color: $white;
  border-color: $blue;

  &:hover {
    background-color: $white;
    color: $violet;
    border-color: $violet;
  }

  &:active {
    background-color: $violet;
    color: $white;
    border-color: transparent;
  }

  &--active {
    background-color: $violet;
    color: $white;
    border-color: transparent;
  }
}

.secondary-btn {
  background-color: transparent;
  color: $blue;
  border-color: $blue;

  &:hover {
    background-color: transparent;
    color: $violet;
    border-color: $violet;
  }

  &:active {
    background-color: $violet;
    color: $white;
    border-color: $violet;
  }
}

.terciary-btn {
  background-color: transparent;
  border-color: transparent;

  &:hover {
    background-color: rgba($violet, .1);
    border-color: rgba($violet, .1);
  }

  &:active {
    background-color: transparent;
    border-color: transparent;
    color: $violet;
  }
}


.accent-btn {
  background-color: $contrast-color ;
  border-radius: 20px;
  text-transform: uppercase;
  padding: .5rem 1.25rem;
  color: $white;

  &:hover {
    background-color: $blue;
  }
}

.primary-btn-icon {
  border-radius: 50%;
  padding: .5rem;
  text-align: center;

  svg {
    max-width: 1.5em;
    margin: 0 auto;
  }
}

.mat-mdc-snack-bar-action button {
  background-color: $main-color;

  &:hover {
    background-color: $hover-button-color;
  }
}


.mat-icon-survi {
  line-height: 16px !important;
  width: 16px  !important;
  height: 16px  !important;
  color: $blue;
}


.dark-theme .mat-icon-survi {
  color: $main-color !important;
}
